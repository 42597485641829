import React from "react";
import Slider from "react-slick";

import style from "./Slider.module.scss";

const SliderImage = ({ data, alt, ...props }) => {
  const settings = {
    customPaging: function(x) {
      return (
        <a href="#">
          <img src={data[x]} className="slider_thumb-img" />
        </a>
      );
    },
    autoplay: false,
    arrows: false,
    dots: true,
    dotsClass: "slider_thumb",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slider_wrapper"
  };

  return (
    <div className={style[`main_slider-wrapper`]}>
      <Slider {...settings}>
        {data.map((_el, i) => {
          return (
            <div key={i} className={style.product_images}>
              <img src={_el} alt={alt} className={style.product_img} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SliderImage;
