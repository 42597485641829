import React from "react";

const Upload = props => (
  <svg viewBox="0 0 13 15" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-4-2h20v20H-4z" />
      <path
        fill="#5252B7"
        fillRule="nonzero"
        d="M5.333 1.667v10.666h2V1.667z"
      />
      <path
        fill="#5252B7"
        fillRule="nonzero"
        d="M6.333 3.414l3.334 3.334 1.414-1.415L6.333.586 1.586 5.333 3 6.748zM12.667 13H0v2h12.667z"
      />
    </g>
  </svg>
);

export default Upload;
