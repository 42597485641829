import React from "react";

const Uploaded = props => (
  <svg viewBox="0 0 16 19" width="1em" height="1em" {...props}>
    <g fill="#433AB2" fillRule="evenodd">
      <path d="M0 0v19h16V4.903L11.077 0H0zm1.847 1.839h8.466l3.84 3.825v11.497H1.848V1.84z" />
      <path d="M16 7h-6V1h1.895v4.105H16zM7.281 11.145L4.966 8.891 0 13.728 1.307 15l3.66-3.564 2.314 2.255 3.23-3.145 4.183 4.073L16 13.347 10.51 8zM6 5.19a.812.812 0 1 0 .001 1.622.812.812 0 0 0 0-1.623M6 8C4.896 8 4 7.103 4 6s.897-2 2-2 2 .897 2 2-.897 2-2 2" />
    </g>
  </svg>
);

export default Uploaded;
