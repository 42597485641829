import React from "react";

import style from "./Input.module.scss";

const getClass = (state, type) => {
  const list = [`${style.input}`];
  list[list.length] = type === "textarea" ? `${style.textarea}` : null;
  list[list.length] = state === "error" ? `${style[`input--error`]}` : null;
  // list[list.length] = `${style[`input--${type}`]}`;

  return list.join(" ");
};

const errorMessage = (state, message) => {
  if (state === "error") {
    return <div className={style.input_message}>{message}</div>;
  }
};

const Input = props => {
  const {
    id,
    name,
    state,
    message,
    type,
    disabled,
    placeholder,
    value,
    onChange
  } = props;

  const InputTag = type === "textarea" ? "textarea" : "input";
  const actualId = id !== "" ? id : name;
  return (
    <>
      <div className={style.input_wrapper}>
        <InputTag
          id={actualId}
          className={getClass(state, type)}
          type={type}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>
      {errorMessage(state, message)}
    </>
  );
};

export default Input;
