import React from "react";

const Down = props => (
  <svg viewBox="0 0 16 11" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-8-10h32v32H-8z" />
      <path fill="#4A5259" d="M14.12.667L8 6.773 1.88.667 0 2.547l8 8 8-8z" />
    </g>
  </svg>
);

export default Down;
