import React from "react";

const Fax = props => (
  <svg viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-1h25v25H-1z" />
      <path
        d="M16.412 11.135a.706.706 0 1 0 0 1.412.706.706 0 0 0 0-1.412"
        fill="#CDCDD2"
      />
      <path
        d="M16.412 11.135a.706.706 0 1 0 0 1.412.706.706 0 0 0 0-1.412z"
        stroke="#CDCDD2"
      />
      <path
        d="M19.47 16.824c0 .143-.092.236-.235.236h-.706v-1.177a.706.706 0 0 0-.705-.706H4.177a.769.769 0 0 0-.074 0 .706.706 0 0 0-.632.706v1.177h-.706c-.143 0-.236-.093-.236-.236V9.295c0-.143.093-.235.236-.235h16.47c.143 0 .236.092.236.235v7.53zm-14.588 3.06h12.236v-3.295H4.882v3.294zM19.235 7.647H4.882V1.53h9.802l2.434 2.478v1.993a.706.706 0 0 0 1.411.02v-2.3c0-.183-.07-.36-.198-.492L15.485.332a.707.707 0 0 0-.507-.213H4.177a.769.769 0 0 0-.074 0 .706.706 0 0 0-.632.705v6.824h-.706c-.9 0-1.647.747-1.647 1.647v7.53c0 .9.747 1.647 1.647 1.647h.706v2.117c0 .39.316.706.706.706h13.647c.39 0 .705-.316.705-.706v-2.117h.706c.9 0 1.647-.747 1.647-1.648V9.295c0-.9-.746-1.647-1.647-1.647z"
        fill="#CDCDD2"
      />
      <path
        d="M20.328 16.824c0 .143-.102.236-.26.236h-.777v-1.177c0-.39-.348-.706-.777-.706H3.486a.932.932 0 0 0-.081 0c-.397.038-.698.343-.696.706v1.177H1.93c-.157 0-.259-.093-.259-.236V9.295c0-.143.102-.235.26-.235h18.137c.157 0 .259.092.259.235v7.53zm-16.065 3.06h13.474v-3.295H4.263v3.294zM20.07 7.647H4.263V1.53h10.794l2.68 2.478v1.993c-.006.39.337.71.766.716.43.005.782-.306.788-.696v-2.3a.673.673 0 0 0-.218-.492L15.939.332a.82.82 0 0 0-.559-.213H3.486a.932.932 0 0 0-.081 0c-.397.037-.698.343-.696.705v6.824H1.93c-.99 0-1.813.747-1.813 1.647v7.53c0 .9.822 1.647 1.813 1.647h.778v2.117c0 .39.348.706.777.706h15.028c.43 0 .777-.316.777-.706v-2.117h.778c.991 0 1.813-.747 1.813-1.648V9.295c0-.9-.822-1.647-1.813-1.647z"
        fill="#CDCDD2"
      />
    </g>
  </svg>
);

export default Fax;
