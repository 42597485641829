import React from "react";
import { Link } from "react-router-dom";

import Icon from "../Icon/Icon";

import style from "./Menu.module.scss";

import { getCategory } from "../../api";

class MenuMobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      sub: [false, false, false, false],
      menu: []
    };
  }

  componentDidMount = async () => {
    const category = await getCategory();

    this.setState({
      menu: category.data
    });
  };

  componentWillReceiveProps = prevProps => {
    const { show } = prevProps;

    if (!show) {
      this.setState({
        open: false,
        sub: [false, false, false, false]
      });
    }
  };

  checkOpen = show => {
    if (!show) {
      this.setState({
        open: false,
        sub: [false, false, false, false]
      });
    }
  };

  getDefaultClass = show => {
    const list = [`${style.mmenu_wrapper}`];
    list[list.length] = show ? `${style[`mmenu_wrapper--show`]}` : null;

    return list.join(" ");
  };

  getIconDefaultClass = (open, id) => {
    const { sub } = this.state;

    const list = [`${style.mmenu_icon}`];
    list[list.length] = open ? `${style[`mmenu_icon--open`]}` : null;
    list[list.length] = sub[id] ? `${style[`msubmenu_icon--open`]}` : null;

    return list.join(" ");
  };

  getMenuInnerDefaultClass = open => {
    const list = [`${style[`mmenu_list--inner`]}`];
    list[list.length] = open ? `${style[`mmenu_list--open`]}` : null;

    return list.join(" ");
  };

  getDefaultSubcategory = open => {
    const list = [`${style.msubcategory_list}`];
    list[list.length] = open ? `${style[`msubcategory_list--open`]}` : null;

    return list.join(" ");
  };

  handleAccordion = () => {
    const { open } = this.state;

    this.setState({
      open: !open
    });
  };

  handleSubmenu = e => {
    const el = e.target;
    const target = el.id;

    this.setState(prevState => {
      const arr = [...prevState.sub];
      arr[target] = !prevState.sub[target];
      return { sub: arr };
    });
  };

  renderMenu = (category, sub, handleClose) => {
    return (
      <>
        {category.map((_el, i) => {
          return (
            <li key={_el.category_id} className={style.msubmenu}>
              <button
                type="button"
                id={i}
                className={style.msubmenu_link}
                onClick={e => this.handleSubmenu(e)}
              >
                {_el.category}
                <Icon
                  type="mplus"
                  size="s"
                  className={this.getIconDefaultClass(null, i)}
                />
              </button>
              <ul id={i} className={this.getDefaultSubcategory(sub[i])}>
                {_el.subcategory.map(_ol => {
                  return (
                    <li key={_ol.subcategory_id} className={style.msubcategory}>
                      <Link
                        to={`/products/${_el.category_id}/${
                          _ol.subcategory_id
                        }`}
                        className={style.msubcategory_link}
                        onClick={handleClose}
                      >
                        {_ol.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </>
    );
  };

  render() {
    const { open, sub, menu } = this.state;
    const { show, handleClose } = this.props;

    return (
      <div className={this.getDefaultClass(show)}>
        <button
          type="button"
          className={style.mmenu_close}
          onClick={handleClose}
        >
          <Icon type="close" size="xl" className={style[`mmenu_close--icon`]} />
        </button>
        <div className={style.mmenu_title}>
          <span>Menu</span>
        </div>
        <div className={style[`mmenu_wrapper--inner`]}>
          <ul className={style.mmenu_list}>
            <li className={style.mmenu}>
              <Link to="/" className={style.mmenu_link} onClick={handleClose}>
                Beranda
              </Link>
            </li>
            <li className={style.mmenu}>
              <Link
                to="/about"
                className={style.mmenu_link}
                onClick={handleClose}
              >
                Tentang Serenite
              </Link>
            </li>
            <li className={style.mmenu}>
              <button
                type="button"
                value="product"
                className={style.mmenu_link}
                onClick={() => this.handleAccordion()}
              >
                Produk
                <Icon
                  type="mdropdown"
                  size="s"
                  className={this.getIconDefaultClass(open)}
                />
              </button>
              <ul className={this.getMenuInnerDefaultClass(open)}>
                {this.renderMenu(menu, sub, handleClose)}
              </ul>
            </li>
            <li className={style.mmenu}>
              <Link
                to="/faq"
                className={style.mmenu_link}
                onClick={handleClose}
              >
                FAQ &amp; Cara Order
              </Link>
            </li>
            <li className={style.mmenu}>
              <Link
                to="/contact"
                className={style.mmenu_link}
                onClick={handleClose}
              >
                Kontak
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default MenuMobile;
