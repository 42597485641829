import React from "react";

import Meta from "../components/Meta/Meta";
import {
  Breadcrumbs,
  BreadcrumbsItem
} from "../components/Breadcrumbs/Breadcrumbs";

const About = () => {
  return (
    <>
      <Meta />
      <div className="pattern_bg">
        <div className="container-mid content_upper">
          <Breadcrumbs>
            <BreadcrumbsItem name="Home" to="/" />
            <BreadcrumbsItem name="Tentang Kami" active />
          </Breadcrumbs>
          <h1 className="page_title">TENTANG KAMI</h1>
        </div>
        <div className="container-mid">
          <div className="logo_wrapper-faq">
            <img
              alt="company_profile"
              src="/assets/images/logo.jpg"
              className="company_logo"
            />
          </div>
          <div className="content_container">
            <h3 className="content_title">PT. Wiryajaya Ekayana</h3>
            <p>
              Serenite berdiri sejak awal tahun 1988 dengan nama Serenite
              Eclatant salah satu pendiri dan CEO Kartika Joswidjaja. Pada saat
              itu belum banyak konveksi di Indonesia, Kesempatan ini ditangkap
              dan di lihat baik oleh CEO kami. CEO kami meyakini bahwa konveksi
              di Indonesia hasil produksi tangan anak Indonesia tidak kalah
              dibandingkan konveksi - konveksi ternama di luar negri.
            </p>
            <p>
              Pada saat itu Serenite belum memiliki badan hukum / legalitas dari
              kegiatan usaha ini. Seiring berjalannya waktu dengan komitmen yang
              kuat Serenite sudah berbadan hukum secara legal di Indonesia atas
              nama PT. Wiryajaya Ekayana secara resmi pada tahun 1989.
            </p>
            <p>
              Serenite adalah konveksi terpercaya di seluruh perusahaan
              Indonesia. Menyediakan layanan sablon atau bordir untuk mencetak
              gambar secara custom di berbagai aneka produk seragam seperti
              T-shirt, Poloshirt, Kemeja, Seragam, Jaket, Sweater, Topi, Goodie
              Bag, Mug, Spanduk, Blazer dan masih banyak produk lainnya.
            </p>
            <p>
              Selama lebih dari 25 tahun kami telah dipercaya berbagai macam
              perusahaan, event organizer, dan advertising agency untuk memenuhi
              semua kebutuhan materi promosi mulai dari konsep, ilustrasi,
              design hingga proses produksi sesuai keinginan mereka.
            </p>
            <h3 className="content_title">Visi</h3>
            <p>
              Menjadi perusahaan konveksi terbesar di Indonesia yang unggul
              untuk perusahaan besar National maupun International.
            </p>
            <h3 className="content_title">Misi</h3>
            <ol>
              <li>Menghasilkan produk yang berkualitas</li>
              <li>
                Memberikan layanan yang baik dan profesional dalam bekerja
              </li>
              <li>
                Mengurangi pengganguran dan menyejahterahkan masyarakat sekitar
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
