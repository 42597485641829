import React from "react";

import Icon from "../../Icon/Icon";

import style from "./Input.module.scss";

class Optselect extends React.Component {
  getDefaultClass = (value, state) => {
    const list = [`${style.select}`];
    list[list.length] = value === "" ? `${style[`select--default`]}` : null;
    list[list.length] = state === "error" ? `${style[`input--error`]}` : null;

    return list.join(" ");
  };

  renderOption = data => {
    const { asKey, asValue } = this.props;

    if (data !== null) {
      return (
        <>
          {data.map(_el => {
            return (
              <optgroup
                key={_el.category_id}
                label={_el.category}
                category={_el.category_id}
              >
                {_el.subcategory.map(_ol => {
                  return (
                    <option key={_ol.subcategory_id} value={_ol[asKey]}>
                      {_ol[asValue]}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </>
      );
    }
  };

  renderError = (state, message) => {
    if (state === "error") {
      return <div className={style.input_message}>{message}</div>;
    }
  };

  render() {
    const { id, value, onChange, state, data, message } = this.props;

    return (
      <>
        <div className={style.input_wrapper}>
          <select
            id={id}
            value={value}
            onChange={onChange}
            className={this.getDefaultClass(value, state)}
          >
            {this.renderOption(data)}
          </select>
          <Icon type="down" size="m" className={style.select_arrow} />
        </div>
        {this.renderError(state, message)}
      </>
    );
  }
}

export default Optselect;
