import React from "react";

import Icon from "../../Icon/Icon";

import style from "./Input.module.scss";

class Select extends React.Component {
  getDefaultClass = (value, state) => {
    const list = [`${style.select}`];
    list[list.length] = value === "" ? `${style[`select--default`]}` : null;
    list[list.length] = state === "error" ? `${style[`input--error`]}` : null;

    return list.join(" ");
  };

  renderOption = data => {
    const { asKey, asValue, defaultOption } = this.props;

    if (data !== null) {
      return (
        <>
          {defaultOption !== undefined ? (
            <option value={defaultOption.key}>{defaultOption.value}</option>
          ) : (
            ""
          )}
          {data.map((_el, i) => {
            return (
              <option key={i} value={_el[asKey]}>
                {_el[asValue]}
              </option>
            );
          })}
        </>
      );
    }
  };

  renderError = (state, message) => {
    if (state === "error") {
      return <div className={style.input_message}>{message}</div>;
    }
  };

  render() {
    const { id, value, onChange, state, data, message } = this.props;

    return (
      <>
        <div className={style.input_wrapper}>
          <select
            id={id}
            value={value}
            onChange={onChange}
            className={this.getDefaultClass(value, state)}
          >
            {this.renderOption(data)}
          </select>
          <Icon type="down" size="m" className={style.select_arrow} />
        </div>
        {this.renderError(state, message)}
      </>
    );
  }
}

export default Select;
