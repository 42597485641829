import React from "react";

const Iplus = props => (
  <svg viewBox="0 0 18 18" width="1em" height="1em" {...props}>
    <path
      d="M17 8a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2h16zM8 1a1 1 0 1 1 2 0v16a1 1 0 0 1-2 0V1z"
      fill="#82828B"
      fillRule="nonzero"
    />
  </svg>
);

export default Iplus;
