import React from "react";
import { Link } from "react-router-dom";

import Meta from "../components/Meta/Meta";
import Button from "../components/Button/Button";
import Banner from "../components/Banner/Banner";
import Feedback from "../components/Feedback/Feedback";
import OrderForm from "../components/Forms/OrderForm";

import style from "../components/Forms/Forms.module.scss";
import button from "../components/Button/Button.module.scss";

import { checkScreenSize } from "../helpers/util";

import { getBanner } from "../api";
import client from "../api/JSON/client-list.json";

const handleForm = () => {
  const modal = document.body;

  Feedback.openModal(modal, `${style.modal__body}`, <OrderForm />);
};

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      banner: []
    };

    this.checkMobile = this.checkMobile.bind(this);
  }

  componentDidMount = async () => {
    const banner = await getBanner();

    this.setState({
      banner: banner.data,
      mobile: false
    });

    window.addEventListener("resize", this.checkMobile);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.checkMobile);
  };

  getClass = () => {
    const { open } = this.state;
    const list = ["hidden_wrapper--customer"];
    list[list.length] = open ? "wrapper--customer" : null;

    return list.join(" ");
  };

  handleToggle = () => {
    const { open } = this.state;

    this.setState({
      open: !open
    });
  };

  checkMobile = () => {
    const isMobile = checkScreenSize();

    this.setState({
      mobile: isMobile
    });
  };

  renderClient = client => {
    const { open, mobile } = this.state;

    if (mobile) {
      return (
        <>
          {client.map((_el, i) => {
            if (i <= 8) {
              return (
                <div key={_el.id} className="logo_wrapper--customer">
                  <img src={_el.logo} alt={_el.name} />
                </div>
              );
            }
          })}
          {open ? null : (
            <div className={button[`link_wrapper-client`]}>
              <button
                type="button"
                className={button[`link-client`]}
                onClick={() => this.handleToggle()}
              >
                Lainnya +
              </button>
            </div>
          )}
          <div className={this.getClass()}>
            {client.map((_el, i) => {
              if (i > 8) {
                return (
                  <div key={_el.id} className="logo_wrapper--customer">
                    <img src={_el.logo} alt={_el.name} />
                  </div>
                );
              }
            })}
          </div>
        </>
      );
    }

    return (
      <>
        {client.map(_el => {
          return (
            <div key={_el.id} className="logo_wrapper--customer">
              <img src={_el.logo} alt={_el.name} />
            </div>
          );
        })}
      </>
    );
  };

  render() {
    const { banner } = this.state;
    const list = client.data;

    return (
      <>
        <Meta />
        <Banner data={banner} onClick={() => handleForm()} />
        <div className="main_wrapper--features">
          <div className="mfeature_wrapper">
            <img
              src="/assets/images/ic_free_shipping.svg"
              alt="fs icon"
              className="mfeature_icon"
            />
            <div className="mfeature_text">
              <div className="mfeature--title">FREE SHIPPING</div>
              <div className="mfeature--text">
                Gratis biaya kirim untuk pemesanan diatas 2500pcs. Khusus
                wilayah Jabodetabek.
              </div>
            </div>
          </div>
          <div className="mfeature_wrapper">
            <img
              src="/assets/images/ic_garansi.svg"
              alt="fs icon"
              className="mfeature_icon"
            />
            <div className="mfeature_text">
              <div className="mfeature--title">GARANSI PEMESANAN</div>
              <div className="mfeature--text">
                Pesanan Anda tidak sesuai ? Kami ganti dengan yang baru, tidak
                di pungut biaya. *Syarat dan ketentuan berlaku.
              </div>
            </div>
          </div>
          <div className="mfeature_wrapper">
            <img
              src="/assets/images/ic_cod.svg"
              alt="fs icon"
              className="mfeature_icon"
            />
            <div className="mfeature_text">
              <div className="mfeature--title">BAYAR DI TEMPAT (COD)</div>
              <div className="mfeature--text">
                Apabila pengambilan produk Serenite secara langsung di workshop
                kami, maka Sistem Bayar di Tempat (COD) dapat berlaku. Jika
                produk Serenite dikirimkan oleh kurir kami, maka pembayaran
                hanya berlaku melalui transfer.
              </div>
            </div>
          </div>
        </div>
        <div className="main_wrapper--products">
          <div className="title--products">
            Lebih Berkarakter &amp; Terpercaya dengan Custom
          </div>
          <div className="text--products">
            Buat Kaos Tim, Seragam Perusahaan hingga Aksesoris yang meningkatkan
            kebanggaan Tim dan Kepercayaan Customer
          </div>
          <div className="mproducts_wrapper">
            <div className="mproducts mproducts-m">
              <Link to="/">
                <img
                  src="/assets/images/ft_main_kaos.png"
                  alt="main kaos"
                  className="mproducts_image"
                />
                <div className="mproducts_overlay" />
                <div className="mproducts_text">
                  <div className="mproducts_name">KAOS</div>
                  <div className="mproducts_category">
                    Oblong, Raglan, V-Neck, Polo Shirt
                  </div>
                </div>
              </Link>
            </div>
            <div className="mproducts">
              <Link to="/">
                <img
                  src="/assets/images/ft_main_seragam.png"
                  alt="main seragam"
                  className="mproducts_image"
                />
                <div className="mproducts_overlay" />
                <div className="mproducts_text">
                  <div className="mproducts_name">SERAGAM</div>
                  <div className="mproducts_category">
                    Oblong, Raglan, V-Neck, Polo Shirt
                  </div>
                </div>
              </Link>
            </div>
            <div className="mproducts">
              <Link to="/">
                <img
                  src="/assets/images/ft_main_jaket.png"
                  alt="main jaket"
                  className="mproducts_image"
                />
                <div className="mproducts_overlay" />
                <div className="mproducts_text">
                  <div className="mproducts_name">JAKET</div>
                  <div className="mproducts_category">
                    Oblong, Raglan, V-Neck, Polo Shirt
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="mproducts_wrapper">
            <div className="mproducts mproducts-s">
              <Link to="/">
                <img
                  src="/assets/images/ft_main_polo.png"
                  alt="main polo"
                  className="mproducts_image"
                />
                <div className="mproducts_overlay" />
                <div className="mproducts_text mproducts_text--center">
                  <div className="mproducts_name">POLO SHIRT</div>
                </div>
              </Link>
            </div>
            <div className="mproducts mproducts-s">
              <Link to="/">
                <img
                  src="/assets/images/ft_main_topi.png"
                  alt="main topi"
                  className="mproducts_image"
                />
                <div className="mproducts_overlay" />
                <div className="mproducts_text mproducts_text--center">
                  <div className="mproducts_name">TOPI</div>
                </div>
              </Link>
            </div>
            <div className="mproducts mproducts-s">
              <Link to="/">
                <img
                  src="/assets/images/ft_main_tas.png"
                  alt="main tas"
                  className="mproducts_image"
                />
                <div className="mproducts_overlay" />
                <div className="mproducts_text mproducts_text--center">
                  <div className="mproducts_name">TAS</div>
                </div>
              </Link>
            </div>
            <div className="mproducts mproducts-s">
              <Link to="/">
                <img
                  src="/assets/images/ft_main_mug.png"
                  alt="main mug"
                  className="mproducts_image"
                />
                <div className="mproducts_overlay" />
                <div className="mproducts_text mproducts_text--center">
                  <div className="mproducts_name">MUG</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="main_wrapper--customer">
          <div className="text--customer">
            Serenite telah dipercaya lebih dari{" "}
            <span className="highlight_customer--mobile">100 Klien</span>
            <div className="highlight--customer">
              {" "}
              <span>100 Klien</span>{" "}
            </div>
            ternama di Indonesia
          </div>
          <div className="inner_wrapper--customer">
            {this.renderClient(list)}
          </div>
        </div>
        <div className="main_wrapper--custom">
          <h1 className="title--custom">Mulai Wujudkan Design Anda</h1>
          <img
            src="/assets/images/divider.svg"
            alt="custom-divider"
            className="separator--custom"
          />
          <div className="text--custom">
            Sudah memiliki desain Anda sendiri? Silakan isi formulir untuk
            melakukan proses pemesanan
          </div>
          <div className="button_wrapper--custom">
            <Button type="button" onClick={() => handleForm()}>
              BUAT SEKARANG
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default Homepage;
