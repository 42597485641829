import React from "react";

const Bfplus = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      d="M24 11v2H0v-2h24zM11 0h2v24h-2V0z"
      fill="#5252B7"
      fillRule="nonzero"
    />
  </svg>
);

export default Bfplus;
