import React from "react";

import { Modal, ModalManager } from "./Modal/Modal";

export default class Feedback {
  static openModal(
    targetElement,
    className,
    content,
    clickOutsideToClose,
    callback
  ) {
    const id = ModalManager.reg();
    ModalManager.open(
      <Modal
        id={id}
        targetElement={targetElement}
        className={className}
        clickOutsideToClose={clickOutsideToClose}
        callback={callback}
      >
        {content}
      </Modal>,
      targetElement
    );
  }
}
