import React from "react";

const Mail = props => (
  <svg viewBox="0 0 23 17" width="1em" height="1em" {...props}>
    <g fillRule="nonzero" fill="none">
      <path d="M-1-4h25v25H-1z" />
      <path
        d="M3.125 3.125v11h16.679v-11H3.125zM22.054.875v15.5H.875V.875h21.179z"
        fill="#CDCDD2"
      />
      <path
        d="M11.464 9.004l8.712-7.84a1.125 1.125 0 1 1 1.505 1.672l-10.217 9.195L1.247 2.836a1.125 1.125 0 1 1 1.506-1.672l8.711 7.84z"
        fill="#CDCDD2"
      />
    </g>
  </svg>
);

export default Mail;
