import React from "react";

const Igdark = props => (
  <svg viewBox="0 0 28 28" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={14} cy={14} r={14} fill="#FFF" />
      <path
        fill="#171133"
        fillRule="nonzero"
        d="M10.8 6h6.4a4.8 4.8 0 0 1 4.8 4.8v6.4a4.8 4.8 0 0 1-4.8 4.8h-6.4A4.8 4.8 0 0 1 6 17.2v-6.4A4.8 4.8 0 0 1 10.8 6zm0 1.6a3.2 3.2 0 0 0-3.2 3.2v6.4a3.2 3.2 0 0 0 3.2 3.2h6.4a3.2 3.2 0 0 0 3.2-3.2v-6.4a3.2 3.2 0 0 0-3.2-3.2h-6.4zM14 18a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-1.6a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8zm4-5.6a.8.8 0 1 1 0-1.6.8.8 0 0 1 0 1.6z"
      />
    </g>
  </svg>
);

export default Igdark;
