import React from "react";

const Ig = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <g fillRule="nonzero" fill="none">
      <path d="M-6-6h32v32H-6z" />
      <path
        d="M6 0h8a6 6 0 0 1 6 6v8a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6zm0 2a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H6zm4 13a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        fill="#cdcdd2"
      />
    </g>
  </svg>
);

export default Ig;
