import React from "react";

const ShareFb = props => (
  <svg viewBox="0 0 14 14" width="1em" height="1em" {...props}>
    <path
      d="M1.4 0h11.2A1.4 1.4 0 0 1 14 1.4v11.2a1.4 1.4 0 0 1-1.4 1.4H1.4A1.4 1.4 0 0 1 0 12.6V1.4A1.4 1.4 0 0 1 1.4 0zm0 1.4v11.2h11.2V1.4H1.4zm6.3 5.603H6.393v-1.4H7.7V4.9c0-1.16.94-2.1 2.1-2.1h.7v1.4h-.7a.7.7 0 0 0-.7.7v.703h1.388v1.4H9.1V11.2H7.7V7.003z"
      fill="#C0BDD4"
      fillRule="nonzero"
    />
  </svg>
);

export default ShareFb;
