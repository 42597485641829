import React from "react";
import { Switch, Route } from "react-router-dom";
import "./scss/global.scss";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import WhatsappButton from "./components/Button/WhatsappButton";

// Pages
// ==============================================
import Homepage from "./pages/homepage";
import Product from "./pages/product";
import About from "./pages/about";
import Faq from "./pages/faq";
import Contact from "./pages/contact";
import ProductDetails from "./pages/product-details";

const App = () => {
  return (
    <>
      <Header />
      <div className="content">
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/faq" component={Faq} />
          <Route path="/contact" component={Contact} />
          <Route path="/products/:category/:subcategory" component={Product} />
          <Route
            path="/product-detail/:category/:subcategory/:productid"
            component={ProductDetails}
          />
          <Route path="/" component={Homepage} />
        </Switch>
        <WhatsappButton />
      </div>
      <Footer />
    </>
  );
};

export default App;
