import React from "react";

import style from "./Button.module.scss";

const WhatsappButton = ({ ...props }) => (
  <div className={`${style[`button--wa`]}`}>
    <a
      href="https://wa.me/6281313311348"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/assets/images/ic_wa.png"
        alt="wa-button"
        className={`${style[`wa__icon`]}`}
      />
    </a>
  </div>
);

export default WhatsappButton;
