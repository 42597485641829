import React from "react";

const ShareTw = props => (
  <svg viewBox="0 0 14 13" width="1em" height="1em" {...props}>
    <path
      d="M12.746 1.716l.944.113-.424.872-.621 1.276a1.02 1.02 0 0 1-.132.272c-.027.043-.118.18-.122.186a1.44 1.44 0 0 0-.055.09v1.634c0 4.182-3.943 6.84-7.667 6.84-.762 0-1.341-.038-2.004-.187-1.09-.244-1.903-.73-2.274-1.579l-.383-.874.93-.087c.84-.08 1.57-.244 2.13-.464C1.53 9.554.669 8.86.669 7.56v-.684h.667c.146 0 .278-.013.396-.035C.58 6.133.001 4.997.001 3.422v-.139c-.005-.794.037-1.218.248-1.76.136-.347.332-.668.596-.962L1.348 0l.49.574C3.448 2.464 5.042 3.61 6.668 3.75c.007-1.745 1.292-3.065 3-3.065.797 0 1.52.31 2.087.87.311.066.641.12.99.161zm-1.474 1.132l-.204-.048-.141-.159c-.341-.384-.77-.587-1.257-.587-.976 0-1.667.713-1.667 1.71 0 .163-.004.266-.024.4-.08.537-.373.969-.976.969-1.999 0-3.802-1.1-5.56-2.976-.091.283-.112.573-.11 1.118l.001.147c0 1.401.553 2.168 1.863 2.595l.465.152v.5c0 .607-.56 1.178-1.38 1.434.376.293 1.144.45 2.387.45h.667v.685c0 .98-1.098 1.748-2.756 2.149.541.17 1.261.243 2.089.243 3.076 0 6.334-2.196 6.334-5.471v-1.71c0-.202.057-.376.151-.557a2.54 2.54 0 0 1 .128-.216l.072-.11.053-.108.256-.527a11.008 11.008 0 0 1-.39-.083z"
      fill="#C0BDD4"
      fillRule="nonzero"
    />
  </svg>
);

export default ShareTw;
