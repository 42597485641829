import React from "react";

import Meta from "../components/Meta/Meta";
import {
  Breadcrumbs,
  BreadcrumbsItem
} from "../components/Breadcrumbs/Breadcrumbs";
import {
  Sidebar,
  SidebarItem,
  SidebarProductMobile
} from "../components/Sidebar/Sidebar";
import ProductCard from "../components/ProductCard/ProductCard";
// import Pagination from "../components/Pagination/Pagination";

import sidebar from "../components/Sidebar/Sidebar.module.scss";

import { getCategory } from "../api";

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mainmenu: [],
      mobile: false,
      menu: [],
      active: 1,
      title: "",
      subtitle: "",
      category: "",
      subcategory: "",
      products: []
    };

    this.checkScreenSize = this.checkScreenSize.bind(this);
  }

  componentDidMount = async () => {
    const { match } = this.props;
    const menu = await getCategory();

    const category = match.params.category;
    const subcategory = match.params.subcategory;

    const data = menu.data;
    const sub = data[category - 1].subcategory;

    this.setState(
      {
        mainmenu: data,
        menu: sub,
        active: sub.find(x => x.subcategory_id == subcategory).subcategory_id,
        title: data[category - 1].category,
        subtitle: sub.find(x => x.subcategory_id == subcategory).name,
        category: category,
        subcategory: subcategory
      },
      () => this.getProducts()
    );

    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  };

  componentWillReceiveProps = async prevProps => {
    const { match } = prevProps;
    const menu = await getCategory();

    const category = match.params.category;
    const subcategory = match.params.subcategory;

    const data = menu.data;
    const sub = data[category - 1].subcategory;

    this.setState(
      {
        menu: sub,
        active: sub.find(x => x.subcategory_id == subcategory).subcategory_id,
        title: data[category - 1].category,
        subtitle: sub.find(x => x.subcategory_id == subcategory).name,
        category: category,
        subcategory: subcategory
      },
      () => this.getProducts()
    );
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.checkScreenSize);
  };

  checkScreenSize = () => {
    const width = window.innerWidth;

    if (width <= 720) {
      this.setState({
        mobile: true
      });
    } else {
      this.setState({
        mobile: false
      });
    }
  };

  handleClick = (id, name) => {
    this.setState({
      active: id,
      title: name
    });
  };

  handleSelect = e => {
    const { mainmenu } = this.state;
    const { history } = this.props;
    const tar = e.target;
    const val = tar.value;
    const category = mainmenu.find(x =>
      x.subcategory.find(_el => _el.subcategory_id == val)
    );

    history.push(`/products/${category.category_id}/${val}`);
  };

  getProducts = async () => {
    const { category, subcategory } = this.state;
    const res = await fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/get_product?category_id=${category}&subcategory_id=${subcategory}&limit=5&offset=0`
    );
    const products = await res.json();

    this.setState({
      products: products.data
    });
  };

  renderSidebar = menu => {
    const { mainmenu, mobile, active, title, category } = this.state;

    if (!mobile) {
      return (
        <Sidebar title={title} customClass="product_hidden">
          {menu.map(_el => {
            return (
              <SidebarItem
                key={_el.subcategory_id}
                name={_el.name}
                active={active === _el.subcategory_id ? true : false}
                to={`/products/${category}/${_el.subcategory_id}`}
              />
            );
          })}
        </Sidebar>
      );
    }
    return (
      <SidebarProductMobile
        id="product_menu"
        data={mainmenu}
        asCategory="category_id"
        asKey="subcategory_id"
        asValue="name"
        value={active}
        customClass={sidebar.sidebar_product}
        onChange={e => this.handleSelect(e)}
      />
    );
  };

  render() {
    const {
      title,
      subtitle,
      menu,
      category,
      subcategory,
      products
    } = this.state;

    return (
      <>
        <Meta />
        <div className="pattern_bg">
          <div className="container container_page">
            {this.renderSidebar(menu)}
            <div className="container_content">
              <div className="container_mobile-title">
                <div className="product_mtitle">{title}</div>
                <div className="product_msubcategory">{subtitle}</div>
              </div>
              <Breadcrumbs customClass="product_hidden">
                <BreadcrumbsItem name="Home" to="/" />
                <BreadcrumbsItem
                  name={title}
                  to={`/products/${category}/${subcategory}`}
                />
                <BreadcrumbsItem name={subtitle} active />
              </Breadcrumbs>
              <div className="content_container container_text">
                <div className="product_container">
                  {products
                    ? products.map(_el => {
                        return (
                          <ProductCard
                            key={_el.id}
                            to={`/product-detail/${category}/${subcategory}/${
                              _el.id
                            }`}
                            productImage={_el.images[0]}
                            productName={_el.name}
                            productDesc={_el.short_description}
                            oos={_el.status !== "active"}
                          />
                        );
                      })
                    : null}
                </div>
                {/* <Pagination /> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Product;
