import React from "react";

const Wa = props => (
  <svg viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <g fillRule="nonzero" fill="none">
      <path d="M-1-1h24v24H-1z" />
      <path
        d="M15.765 13.135c-.257-.129-1.523-.752-1.759-.838-.236-.085-.408-.128-.58.13-.171.257-.664.837-.815 1.009-.15.172-.3.193-.557.064-.258-.128-1.088-.4-2.071-1.278-.766-.682-1.282-1.526-1.433-1.783-.15-.258-.016-.397.113-.526.116-.115.258-.3.387-.45.128-.151.171-.259.257-.43.086-.172.043-.323-.021-.452-.065-.128-.58-1.396-.794-1.912-.21-.502-.422-.434-.58-.442-.15-.007-.321-.009-.493-.009a.946.946 0 0 0-.687.323c-.236.257-.901.88-.901 2.148 0 1.267.923 2.491 1.051 2.663.13.172 1.816 2.773 4.4 3.888.613.266 1.093.424 1.467.543.617.196 1.178.168 1.622.102.495-.074 1.523-.623 1.738-1.225.214-.601.214-1.117.15-1.224-.064-.107-.236-.172-.494-.3m-4.698 6.414h-.003a8.552 8.552 0 0 1-4.36-1.194l-.312-.185-3.242.85.865-3.16-.204-.325a8.545 8.545 0 0 1-1.31-4.558c.003-4.723 3.845-8.565 8.57-8.565 2.287.001 4.438.893 6.055 2.512a8.514 8.514 0 0 1 2.506 6.06c-.001 4.723-3.844 8.565-8.565 8.565m7.29-15.855a10.238 10.238 0 0 0-7.29-3.022C5.387.673.764 5.296.762 10.977c0 1.817.474 3.59 1.376 5.152L.676 21.47l5.463-1.433c1.505.821 3.2 1.254 4.924 1.254h.004c5.68 0 10.303-4.623 10.305-10.304a10.243 10.243 0 0 0-3.015-7.29"
        fill="#cdcdd2"
      />
    </g>
  </svg>
);

export default Wa;
