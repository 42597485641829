import MainService from "./base";

const handleGETRequest = async (api, { ...body }) => {
  const {
    result: {
      body: { data, error }
    }
  } = await MainService(api).doRequest({ params: { ...body } });
  return {
    data,
    error
  };
};

const handlePOSTRequest = async (api, { ...body }) => {
  const {
    result: {
      body: { data, error }
    }
  } = await MainService(api).doRequest({ body: { ...body } });
  return {
    data,
    error
  };
};

/** Edit this part */
export const getCategory = () => handleGETRequest("getCategory");
export const getBanner = () => handleGETRequest("getBanner");
