import React from "react";

const Close = props => (
  <svg viewBox="0 0 26 26" width="1em" height="1em" {...props}>
    <path
      d="M25.02 23.607a1 1 0 1 1-1.413 1.414L.979 2.393A1 1 0 1 1 2.393.98l22.628 22.628zM23.608.979a1 1 0 1 1 1.414 1.414L2.393 25.021A1 1 0 1 1 .98 23.607L23.607.979z"
      fill="#9C9DB7"
      fillRule="nonzero"
    />
  </svg>
);

export default Close;
