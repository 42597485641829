import React from "react";

const Mdropdown = props => (
  <svg viewBox="0 0 16 10" width="1em" height="1em" {...props}>
    <path
      d="M14 .586L15.414 2 8 9.414.586 2 2 .586l6 6z"
      fill="#9D9D9D"
      fillRule="nonzero"
    />
  </svg>
);

export default Mdropdown;
