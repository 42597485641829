import React from "react";

const Wadark = props => (
  <svg viewBox="0 0 28 28" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={14} cy={14} r={14} fill="#FFF" />
      <path
        fill="#171133"
        fillRule="nonzero"
        d="M17.61 15.588c-.198-.099-1.172-.578-1.354-.644-.182-.067-.314-.1-.446.099-.132.198-.511.644-.627.777-.116.132-.231.148-.43.05-.197-.1-.836-.31-1.592-.984a5.902 5.902 0 0 1-1.102-1.373c-.116-.198-.013-.305.087-.404.089-.089.198-.231.297-.347.099-.116.132-.198.198-.33.066-.133.033-.248-.017-.347-.05-.1-.445-1.075-.61-1.471-.161-.387-.325-.334-.446-.34a7.944 7.944 0 0 0-.38-.007.728.728 0 0 0-.528.247c-.182.199-.694.678-.694 1.653s.71 1.917.81 2.05c.098.132 1.396 2.133 3.384 2.99.472.205.841.327 1.129.418.474.15.906.13 1.248.079.38-.057 1.172-.48 1.337-.942.165-.463.165-.86.115-.942-.05-.083-.181-.133-.38-.232m-3.614 4.935h-.003a6.58 6.58 0 0 1-3.354-.918l-.24-.143-2.494.654.665-2.431-.156-.25a6.574 6.574 0 0 1-1.008-3.507 6.6 6.6 0 0 1 6.593-6.589c1.76 0 3.414.687 4.659 1.933a6.55 6.55 0 0 1 1.928 4.662c-.002 3.633-2.958 6.59-6.59 6.59m5.608-12.199A7.877 7.877 0 0 0 13.995 6c-4.37 0-7.927 3.556-7.929 7.928 0 1.397.365 2.761 1.059 3.964L6 22l4.203-1.103a7.921 7.921 0 0 0 3.789.965h.003c4.37 0 7.927-3.556 7.928-7.928a7.88 7.88 0 0 0-2.32-5.609"
      />
    </g>
  </svg>
);

export default Wadark;
