import React from "react";
import { Link } from "react-router-dom";

import Meta from "../components/Meta/Meta";
import {
  Breadcrumbs,
  BreadcrumbsItem
} from "../components/Breadcrumbs/Breadcrumbs";
import Button from "../components/Button/Button";
import Feedback from "../components/Feedback/Feedback";
import ProductCard from "../components/ProductCard/ProductCard";
import Accordion from "../components/Accordion/Accordion";
import OrderForm from "../components/Forms/OrderForm";
import SliderImage from "../components/Slider/Slider";

import style from "../components/Forms/Forms.module.scss";
import accordion from "../components/Accordion/Accordion.module.scss";
import card from "../components/ProductCard/ProductCard.module.scss";

const handleForm = () => {
  const modal = document.body;

  Feedback.openModal(modal, `${style.modal__body}`, <OrderForm />);
};

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "",
      related: [],
      images: []
    };
  }

  componentDidMount = async () => {
    const { match } = this.props;

    const category = match.params.category;
    const subcategory = match.params.subcategory;
    const productid = match.params.productid;

    this.setState(
      {
        category: category,
        subcategory: subcategory,
        productid: productid
      },
      () => this.getProductDetail()
    );
  };

  getProductDetail = async () => {
    const { category, subcategory, productid } = this.state;
    const res = await fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/get_product_detail?category_id=${category}&subcategory_id=${subcategory}&product_id=${productid}`
    );
    const detail = await res.json();
    const data = detail.data;

    this.setState({
      name: data.name,
      description: data.detail_product,
      related: data.related_products,
      images: data.images
    });
  };

  renderRelated = (related, category, subcategory) => {
    return (
      <>
        {related.map(_el => {
          return (
            <ProductCard
              key={_el.id}
              to={`/products/${category}/${subcategory}/${_el.id}`}
              productImage={_el.images[0]}
              productName={_el.name}
              productDesc={_el.short_description}
              className={card.related_products}
              oos={_el.status !== "active"}
            />
          );
        })}
      </>
    );
  };

  render() {
    const {
      name,
      description,
      related,
      images,
      category,
      subcategory
    } = this.state;

    return (
      <>
        <Meta />
        <div className="pattern_bg">
          <div className="container container_med">
            <div className="details_image-wrapper">
              <SliderImage data={images} />
              {/* <div className="details_share">
                Bagikan:
                <div className="details_share-link">
                  <a
                    href="www.facebook.com/serenite.id"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="fb" size="s" style={{ marginRight: "8px" }} />
                    Facebook
                  </a>
                </div>
                <div className="details_share-link">
                  <a
                    href="www.facebook.com/serenite.id"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="ig" size="s" style={{ marginRight: "8px" }} />
                    Instagram
                  </a>
                </div>
              </div> */}
            </div>
            <div className="details_wrapper">
              <Breadcrumbs customClass="product_hidden">
                <BreadcrumbsItem name="Home" to="/" />
                <BreadcrumbsItem name="Kaos" to="/products" />
                <BreadcrumbsItem name={name} active />
              </Breadcrumbs>
              <div className="product_details-name">{name}</div>
              <div className="product_details-desc">{description}</div>
              <Accordion
                className="product_detail-accordion"
                titleClass={accordion.detail_accordion}
                contentClass={accordion[`detail_accordion-content`]}
              >
                <div title="DETAIL PRODUK">
                  <div>DESKRIPSI PRODUK</div>
                  <p>
                    Desain di samping adalah contoh Kemeja yang dapat Anda
                    gunakan. Anda dapat mengganti kombinasi warna dan model
                    serta dapat menambahkan logo dan tulisan sesuai dengan
                    keinginan dan kebutuhan.
                  </p>
                  <div>SIZE CHART</div>
                  <p>
                    Standard ukuran yang kami gunakan adalah ukuran orang
                    Indonesia pada umumnya. Namun jika Anda menginginkan produk
                    dengan ukuran luar (Eropa) tetap dapat kami sediakan. Acuan
                    standard ukuran kami adalah sebagai berikut:
                  </p>
                  <img
                    src="/assets/images/tshirt_size_chart.jpg"
                    alt="size-chart"
                    className={accordion[`detail_accordion-img`]}
                  />
                </div>
                <div title="CARA PEMESANAN">
                  <p>
                    Desain di samping adalah contoh Kemeja yang dapat Anda
                    gunakan. Anda dapat mengganti kombinasi warna dan model
                    serta dapat menambahkan logo dan tulisan sesuai dengan
                    keinginan dan kebutuhan.
                  </p>
                </div>
              </Accordion>
              <div className="button_wrapper-order">
                <Button type="button" onClick={() => handleForm()}>
                  BUAT SEKARANG
                </Button>
              </div>
            </div>
          </div>
          <div className="related_products-title">
            Lihat Juga Produk Lainnya
          </div>
          <div className="container container-mid">
            <div className="related_container">
              {this.renderRelated(related, category, subcategory)}
            </div>
            <div className="related_link">
              <Link to="/" className="seemore_link">
                LIHAT SEMUA
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductDetails;
