import React from "react";
import { Link } from "react-router-dom";

import Icon from "../Icon/Icon";
import Menu from "../Menu/Menu";
import MenuMobile from "../Menu/MenuMobile";

import style from "./Header.module.scss";
import button from "../Button/Button.module.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      menu: false
    };
  }

  handleHover = () => {
    this.setState({
      active: true
    });
  };

  handleLeave = () => {
    this.setState({
      active: false
    });
  };

  handleMenu = () => {
    this.setState({
      menu: true
    });
  };

  handleClose = () => {
    this.setState({
      menu: false
    });
  };

  render() {
    const { active, menu } = this.state;

    return (
      <>
        <MenuMobile show={menu} handleClose={() => this.handleClose()} />
        <div className={style.header}>
          <div className="container">
            <div className={`${style[`header_logo-container`]}`}>
              <Link to="/">
                <img
                  src="/assets/images/serenite_logo.png"
                  alt="serenite-logo"
                  className={style.header_logo}
                />
              </Link>
            </div>
            <div className={`${style[`header_hamburger-wrapper`]}`}>
              <button
                type="button"
                className={button.link_hamburger}
                onClick={() => this.handleMenu()}
              >
                <Icon
                  type="hamburger"
                  size="m"
                  className={style.header_hamburger}
                />
              </button>
            </div>
            <div className={`${style[`header_menu-wrapper`]}`}>
              <ul className={`${style[`header_menu-list`]}`}>
                <li className={style.header_menu}>
                  <Link to="/" className={`${button.link} ${button.link_menu}`}>
                    <span>Beranda</span>
                  </Link>
                </li>
                <li className={style.header_menu}>
                  <Link
                    to="/about"
                    className={`${button.link} ${button.link_menu}`}
                  >
                    <span>Tentang Serenite</span>
                  </Link>
                </li>
                <li className={style.header_menu}>
                  <button
                    className={`${button.link} ${button.link_menu}`}
                    onMouseEnter={() => this.handleHover()}
                    onMouseLeave={() => this.handleLeave()}
                  >
                    <span>
                      Produk
                      {active ? (
                        <i className="fa fa-chevron-up" />
                      ) : (
                        <i className="fa fa-chevron-down" />
                      )}
                    </span>
                  </button>
                </li>
                <li className={style.header_menu}>
                  <Link
                    to="/faq"
                    className={`${button.link} ${button.link_menu}`}
                  >
                    <span>FAQ &amp; Cara Order</span>
                  </Link>
                </li>
                <li className={style.header_menu}>
                  <Link
                    to="/contact"
                    className={`${button.link} ${button.link_menu}`}
                  >
                    <span>Kontak</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Menu
          active={active}
          onMouseEnter={() => this.handleHover()}
          onMouseLeave={() => this.handleLeave()}
        />
      </>
    );
  }
}

export default Header;
