import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Button from "../Button/Button";

import style from "./Banner.module.scss";
import button from "../Button/Button.module.scss";

const PrevArrow = props => {
  const { className, onClick } = props;

  return <div className={className} onClick={onClick} />;
};

const NextArrow = props => {
  const { className, onClick } = props;

  return <div className={className} onClick={onClick} />;
};

class Banner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: false
    };
  }

  checkScreen = () => {
    const width = window.innerWidth;

    if (width <= 640) {
      this.setState({
        mobile: true
      });
    } else {
      this.setState({
        mobile: false
      });
    }
  };

  renderCustom = children => (
    <div>
      <div className={style.banner_inner}>
        <div className={style.banner_text}>
          <div className={style[`banner--title`]}>
            Konveksi Terpercaya &amp; Berkualitas
          </div>
          <div className={style[`banner--text`]}>
            Serenite membantu mewujudkan ide dalam branding Pakaian Tim &amp;
            Perusahaan
          </div>
          <div className={style.banner_link}>
            <Link to="/" className={button.button}>
              LIHAT PRODUK
            </Link>
          </div>
        </div>
        <div className={style[`banner_wrapper--img`]}>
          <img
            src="/assets/images/banner_mockup.png"
            alt="banner"
            className={style.banner_img}
          />
        </div>
        {children}
      </div>
    </div>
  );

  render() {
    const { data, onClick } = this.props;

    const settings = {
      dots: true,
      autoplay: true,
      autoplaySpeed: 5000,
      adaptiveHeight: true,
      infinite: true,
      lazyload: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: `${style.banner_wrapper}`,
      PrevArrow: <PrevArrow />,
      NextArrow: <NextArrow />
    };

    return (
      <div className={style[`main_wrapper--banner`]}>
        <Slider {...settings}>
          {data.map((_el, i) => {
            return (
              <div key={i} className={style.banner}>
                <Button type="button" onClick={onClick}>
                  <img
                    src={_el.image}
                    alt={_el.name}
                    className={style.banner_img}
                  />
                </Button>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default Banner;
