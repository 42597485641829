import React from "react";

import AccordionSection from "./AccordionSection";

import style from "./Accordion.module.scss";

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: -1
    };
  }

  getDefaultClass = i => {
    const { className } = this.props;
    const { show } = this.state;

    const list = [`${style.accordion_wrapper}`];
    list[list.length] = show === i ? `${style.accordion_show}` : ` `;
    list[list.length] = className;

    return list.join(" ");
  };

  onClick = key => {
    this.setState(
      {
        show: key
      },
      () => this.getDefaultClass()
    );
  };

  render() {
    const { children, titleClass, contentClass } = this.props;

    return (
      <>
        {children.map((_el, i) => (
          <AccordionSection
            key={i}
            title={_el.props.title}
            onClick={() => this.onClick(i)}
            show={this.getDefaultClass(i)}
            className={titleClass}
            contentClass={contentClass}
          >
            {_el.props.children}
          </AccordionSection>
        ))}
      </>
    );
  }
}

export default Accordion;
