import React from "react";
import { Link } from "react-router-dom";

import Icon from "../Icon/Icon";

import style from "./Footer.module.scss";

const date = new Date();
const year = date.getFullYear();

const Footer = () => (
  <footer>
    <div className={style.footer_container}>
      <div className={`${style[`footer_container-inner`]}`}>
        <div className={style.footer_logo}>
          <img
            alt="sereniteid"
            src="/assets/images/serenite_logo-wht.png"
            className={`${style[`footer_logo-img`]}`}
          />
        </div>
        <div className={`${style[`footer_cp-name`]}`}>
          PT. Wiryajaya Ekayana
        </div>
        <div className={`${style[`footer_cp-address`]}`}>
          Taman Alfa Indah Blok D8 No. 14 - 15, <br />
          Joglo, Jakarta Barat <br />
          DKI Jakarta 11640
        </div>
        <div className={style.footer_maps}>
          <a
            href="https://www.google.com/maps/place/Sablon+Kaos+%26+Konveksi+Seragam+-+PT.+Wiryajaya+Ekayana/@-6.2219047,106.7517413,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69f0cc89478ccb:0x8ece0c7c8d2436f5!8m2!3d-6.22191!4d106.75393"
            target="_blank"
            rel="noopener noreferrer"
            className={style.footer_map}
          >
            <Icon
              type="map"
              size="s"
              className={`${style[`footer_map-icon`]}`}
            />{" "}
            <span>LIHAT MAP</span>
          </a>
        </div>
      </div>
      <div className={style.footer_separator} />
      <div className={`${style[`footer_container-inner`]}`}>
        <div className={style.footer_title}>HOTLINE</div>
        <ul className={style.footer_contacts}>
          <li className={`${style[`footer_contacts-info`]}`}>
            <Icon
              type="wa"
              size="m"
              className={`${style[`footer_contacts-icon`]}`}
            />
            081313311348
          </li>
          <li className={`${style[`footer_contacts-info`]}`}>
            <Icon
              type="phone"
              size="m"
              className={`${style[`footer_contacts-icon`]}`}
            />
            021 - 5857627 / 28
          </li>
          <li className={`${style[`footer_contacts-info`]}`}>
            <Icon
              type="fax"
              size="m"
              className={`${style[`footer_contacts-icon`]}`}
            />
            021 - 5842754 (Fax/Direct)
          </li>
          <li
            className={`${style[`footer_contacts-info`]} ${
              style[`contacts_info-top`]
            }`}
          >
            <Icon
              type="mail"
              size="m"
              className={`${style[`footer_contacts-icon`]}`}
            />
            <div>
              <a
                href="mailto:contact@serenite.id"
                className={style.footer_link}
              >
                contact@serenite.id
              </a>
              <div className={`${style[`footer_contacts-multi`]}`}>
                <a
                  href="mailto:order@serenite.id"
                  className={style.footer_link}
                >
                  order@serenite.id
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className={style.footer_ft}>
        <div className={style.footer_links}>
          <a
            href="https://wa.me/6281313311348"
            target="_blank"
            rel="noopener noreferrer"
            className={style.footer_socmed}
          >
            <Icon
              type="wadark"
              size="l"
              className={`${style[`footer_links-icon`]}`}
            />
          </a>
          <a
            href="http://www.instagram.com/serenite.id"
            target="_blank"
            rel="noopener noreferrer"
            className={style.footer_socmed}
          >
            <Icon
              type="igdark"
              size="l"
              className={`${style[`footer_links-icon`]}`}
            />
          </a>
          <a
            href="http://www.facebook.com/serenite.id"
            target="_blank"
            rel="noopener noreferrer"
            className={style.footer_socmed}
          >
            <Icon
              type="fbdark"
              size="l"
              className={`${style[`footer_links-icon`]} ${
                style[`footer_links-icon--sm`]
              }`}
            />
          </a>
          <a
            href="https://twitter.com/SereniteID"
            target="_blank"
            rel="noopener noreferrer"
            className={style.footer_socmed}
          >
            <Icon
              type="twtdark"
              size="s"
              className={`${style[`footer_links-icon`]} ${
                style[`footer_links-icon--sm`]
              }`}
            />
          </a>
        </div>
        <div className={style.footer_copy}>
          &copy;{year} Serenite.id. All rights reserved.
        </div>
      </div>
    </div>
    <div className={style.footer_ornament}>
      <div className={`${style[`footer_ornament-green`]}`} />
      <div className={`${style[`footer_ornament-purple`]}`} />
    </div>
  </footer>
);

export default Footer;
