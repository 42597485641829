import React from "react";

const Mplus = props => (
  <svg viewBox="0 0 17 17" width="1em" height="1em" {...props}>
    <path
      d="M9.142 15.142a1 1 0 0 1-2 0v-14a1 1 0 0 1 2 0v14zm6-8a1 1 0 1 1 0 2h-14a1 1 0 0 1 0-2h14z"
      fill="#433AB1"
      fillRule="nonzero"
    />
  </svg>
);

export default Mplus;
