import React from "react";

const Rchevron = props => (
  <svg viewBox="0 0 56 56" width="1em" height="1em" {...props}>
    <g fill="#FFF" fillRule="nonzero">
      <path d="M21.879 16L24 13.879 38.121 28 24 42.121 21.879 40l12-12z" />
      <path d="M28 1C13.088 1 1 13.088 1 28s12.088 27 27 27 27-12.088 27-27S42.912 1 28 1zm0-1c15.464 0 28 12.536 28 28S43.464 56 28 56 0 43.464 0 28 12.536 0 28 0z" />
    </g>
  </svg>
);

export default Rchevron;
