import React from "react";

const Fbdark = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <g fillRule="nonzero" fill="none">
      <path d="M-6-6h32v32H-6z" />
      <path
        d="M2 0h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 2v16h16V2H2zm9 8.004H9.133v-2H11V7a3 3 0 0 1 3-3h1v2h-1a1 1 0 0 0-1 1v1.004h1.982v2H13V16h-2v-5.996z"
        fill="#171133"
      />
    </g>
  </svg>
);

export default Fbdark;
