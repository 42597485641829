import React from "react";
import { Link } from "react-router-dom";

import style from "./Menu.module.scss";
import button from "../Button/Button.module.scss";

import { getCategory } from "../../api";

const getDefaultClass = active => {
  const list = [`${style.menu_wrapper}`];
  list[list.length] = active ? `${style.menu_open}` : null;

  return list.join(" ");
};

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: []
    };
  }

  componentDidMount = async () => {
    const category = await getCategory();

    this.setState({
      menu: category.data
    });
  };

  renderMenu = category => {
    return (
      <>
        {category.map(_el => {
          return (
            <div key={_el.category_id} className={style.menu_inner}>
              <img
                src={_el.icon}
                alt={_el.category}
                className={style.menu_icon}
              />
              <div className={style.menu_title}>{_el.category}</div>
              <ul className={style.menu_list}>
                {_el.subcategory.map(_ol => {
                  return (
                    <li key={_ol.subcategory_id} className={style.submenu}>
                      <Link
                        to={`/products/${_el.category_id}/${
                          _ol.subcategory_id
                        }`}
                        className={button.link_submenu}
                      >
                        {_ol.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </>
    );
  };

  render() {
    const { menu } = this.state;
    const { active, onMouseEnter, onMouseLeave } = this.props;

    return (
      <div
        className={getDefaultClass(active)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="container">{this.renderMenu(menu)}</div>
      </div>
    );
  }
}

export default Menu;
