const apiList = [
  {
    url: "get_product_category",
    children: [
      {
        name: "getCategory",
        url: "",
        method: "GET"
      }
    ]
  },
  {
    url: "get_banner",
    children: [
      {
        name: "getBanner",
        utl: "",
        method: "GET"
      }
    ]
  }
];

export default apiList;
