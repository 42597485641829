import React from "react";

const Hamburger = props => (
  <svg viewBox="0 0 22 19" width="1em" height="1em" {...props}>
    <path
      d="M1 2a1 1 0 1 1 0-2h20a1 1 0 0 1 0 2H1zm0 8.333a1 1 0 1 1 0-2h20a1 1 0 0 1 0 2H1zm0 8.334a1 1 0 0 1 0-2h20a1 1 0 1 1 0 2H1z"
      fill="#9394A2"
      fillRule="nonzero"
    />
  </svg>
);

export default Hamburger;
