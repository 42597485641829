import React from "react";

import Meta from "../components/Meta/Meta";
import {
  Breadcrumbs,
  BreadcrumbsItem
} from "../components/Breadcrumbs/Breadcrumbs";
import {
  Sidebar,
  SidebarItem,
  SidebarMobile
} from "../components/Sidebar/Sidebar";
import Accordion from "../components/Accordion/Accordion";

const menu = [
  {
    id: "1",
    name: "FAQ"
  },
  {
    id: "2",
    name: "Cara Order"
  }
];

class Faq extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: false,
      active: "1",
      title: "FAQ"
    };

    this.checkScreenSize = this.checkScreenSize.bind(this);
  }

  componentDidMount = () => {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.checkScreenSize);
  };

  checkScreenSize = () => {
    const width = window.innerWidth;

    if (width <= 720) {
      this.setState({
        mobile: true
      });
    } else {
      this.setState({
        mobile: false
      });
    }
  };

  handleSelect = e => {
    const tar = e.target;
    const val = tar.value;

    this.setState({
      active: val,
      title: tar[val - 1].text
    });
  };

  handleClick = (e, id, name) => {
    e.preventDefault();

    this.setState({
      active: id,
      title: name
    });
  };

  renderSidebar = () => {
    const { mobile, active } = this.state;

    if (!mobile) {
      return (
        <Sidebar>
          {menu.map(_el => {
            return (
              <SidebarItem
                key={_el.id}
                name={_el.name}
                type="button"
                active={active == _el.id ? true : false}
                to="#"
                onClick={e => this.handleClick(e, _el.id, _el.name)}
              />
            );
          })}
        </Sidebar>
      );
    }
    return (
      <SidebarMobile
        id="faq_menu"
        data={menu}
        asKey="id"
        asValue="name"
        value={active}
        onChange={e => this.handleSelect(e)}
      />
    );
  };

  renderFaq = () => {
    const { mobile } = this.state;

    if (!mobile) {
      return (
        <>
          <h4 className="content_title-m">Bagaimana cara pemesanannya?</h4>
          <p>
            Pemesanan dilakukan dengan adanya beberapa tahap yang sangat
            sederhana:
          </p>
          <ul>
            <li>
              Pertama customer cukup mengirimkan design / model kepada kami lalu
              kami kirimkan surat penawaran harga
            </li>
            <li>
              Apabila customer telah menyetujui maka kita lanjutkan ke tahap
              pengisian formulir yang dimana customer akan diminta pengisian
              data - data lengkap serta ukuran untuk setiap design tersebut
            </li>
            <li>
              Lalu setelah pengisian formulir, customer mengirim logo, font yang
              digunakan serta design / model ke email kami{" "}
              <a href="mailto:order@serenite.id">
                <b>order@serenite.id</b>
              </a>
            </li>
            <li>
              Lalu customer memberikan uang muka sebesar dp 50% atau pembayaran
              full payment 100%, Setelah itu proses produksi akan berlangsung
              setelah proses pembayaran selesai
            </li>
            <li>
              Apabila proses produksi sudah selesai, maka customer melunasi sisa
              pembayaran sebelum kami kirimkan.
            </li>
          </ul>
          <h4 className="content_title-m">Berapa harga produk?</h4>
          <p>
            Untuk masing - masing produk tentunya berbeda - beda dikarenakan
            jumlahnya pemesanan, jenis bahan yang digunakan, dan tingkat
            kesulitan model tersebut, dan deadline tertentu.
          </p>
          <h4 className="content_title-m">
            Berapa lama waktu proses produksi?
          </h4>
          <p>
            Waktu proses produksi ditentukan oleh banyaknya quantity, kerumitan
            design atau model, ketersediaan bahan, dan kepadatan produksi. Jika
            proses produksi tidak padat maka akan bisa lebih cepat proses
            pengerjaannya.
          </p>
          <h4 className="content_title-m">Apakah ada minimal pemesanan?</h4>
          <p>
            Jumlah yang kita minimalkan untuk produk pemesanan adalah 50pcs
            untuk masing - masing produk, namun untuk produk seperti kemeja
            seragam, repear order dan project lainnya customer bisa
            berkonsultasi kepada kami untuk mengenai hal ini.
          </p>
          <h4 className="content_title-m">
            Bagaimana cara pembayaran untuk proses produksi?
          </h4>
          <p>
            Pembayaran cukup dilakukan 2 tahap, yang pertama sebelum memulai
            proses produksi customer memberikan uang muka sebesar 50%, setelah
            proses produksi selesai customer melunasi sisa pembayaran.
          </p>
          <h4 className="content_title-m">
            Jika saya tidak di Jakarta apakah saya dapat melakukan pemesanan?
          </h4>
          <p>
            Tentu saja bisa, karena sudah tidak ada lagi batasan dalam
            berbisnis. Kami mengirim ke seluruh Indonesia, Anda dapat tetap
            melakukan pemesanan design dan detail pemesanan dapat Anda kirimkan
            melalui Whatsapp nomor yang tersedia atau melalui Line serta e-mail
            yang kami cantumkan.
          </p>
        </>
      );
    }

    return (
      <Accordion>
        <div title="Bagaimana cara pemesanannya?">
          <p>
            Pemesanan dilakukan dengan adanya beberapa tahap yang sangat
            sederhana:
          </p>
          <ul>
            <li>
              Pertama customer cukup mengirimkan design / model kepada kami lalu
              kami kirimkan surat penawaran harga
            </li>
            <li>
              Apabila customer telah menyetujui maka kita lanjutkan ke tahap
              pengisian formulir yang dimana customer akan diminta pengisian
              data - data lengkap serta ukuran untuk setiap design tersebut
            </li>
            <li>
              Lalu setelah pengisian formulir, customer mengirim logo, font yang
              digunakan serta design / model ke email kami{" "}
              <a href="mailto:order@serenite.id">
                <b>order@serenite.id</b>
              </a>
            </li>
            <li>
              Lalu customer memberikan uang muka sebesar dp 50% atau pembayaran
              full payment 100%, Setelah itu proses produksi akan berlangsung
              setelah proses pembayaran selesai
            </li>
            <li>
              Apabila proses produksi sudah selesai, maka customer melunasi sisa
              pembayaran sebelum kami kirimkan.
            </li>
          </ul>
        </div>
        <div title="Berapa harga produk?">
          <p>
            Untuk masing - masing produk tentunya berbeda - beda dikarenakan
            jumlahnya pemesanan, jenis bahan yang digunakan, dan tingkat
            kesulitan model tersebut, dan deadline tertentu.
          </p>
        </div>
        <div title="Berapa lama waktu proses produksi?">
          <p>
            Waktu proses produksi ditentukan oleh banyaknya quantity, kerumitan
            design atau model, ketersediaan bahan, dan kepadatan produksi. Jika
            proses produksi tidak padat maka akan bisa lebih cepat proses
            pengerjaannya.
          </p>
        </div>
        <div title="Apakah ada minimal pemesanan?">
          <p>
            Jumlah yang kita minimalkan untuk produk pemesanan adalah 50pcs
            untuk masing - masing produk, namun untuk produk seperti kemeja
            seragam, repear order dan project lainnya customer bisa
            berkonsultasi kepada kami untuk mengenai hal ini.
          </p>
        </div>
        <div title="Bagaimana cara pembayaran untuk proses produksi?">
          <p>
            Pembayaran cukup dilakukan 2 tahap, yang pertama sebelum memulai
            proses produksi customer memberikan uang muka sebesar 50%, setelah
            proses produksi selesai customer melunasi sisa pembayaran.
          </p>
        </div>
        <div title="Jika saya tidak di Jakarta apakah saya dapat melakukan pemesanan?">
          <p>
            Tentu saja bisa, karena sudah tidak ada lagi batasan dalam
            berbisnis. Kami mengirim ke seluruh Indonesia, Anda dapat tetap
            melakukan pemesanan design dan detail pemesanan dapat Anda kirimkan
            melalui Whatsapp nomor yang tersedia atau melalui Line serta e-mail
            yang kami cantumkan.
          </p>
        </div>
      </Accordion>
    );
  };

  renderCO = () => {
    return (
      <>
        <p>
          <b>
            Jika Anda mengalami kesulitan dalam pemesanan, ikuti langkah dibawah
            ini
          </b>
        </p>
        <ol>
          <li>
            Sebelum memulai pesanan customer mengisi formulir terlebih dahulu
            untuk di data, melalui link berikut:{" "}
            <a href="https://goo.gl/forms/2p5A5WkeYLri99MA3">
              https://goo.gl/forms/2p5A5WkeYLri99MA3
            </a>
          </li>
          <li>
            Setelah isi formulir, customer kirimkan logo yang high resolution
            atau vector tanpa background, font yang digunakan, dan design kaos /
            jaket / seragam ke email{" "}
            <a href="mailto:order@serenite.id">
              <b>order@serenite.id</b>
            </a>
          </li>
          <li>
            Untuk proses pembayaran customer harap melakukan pembayaran sebesar
            DP 50% atau full payment, Jangan lupa untuk mengirimkan bukti
            transfernya.
          </li>
          {/* <div className="content_center">
            <p>Transfer ke Rekening:</p>
            <div className="content_highlight">
              <img
                alt="bank logo"
                src="/assets/images/bni_logo.png"
                className="bank_logo"
              />
              <div className="highlight_number">0682213434</div>
              <div className="highlight_text">a/n Jerry Manggala</div>
            </div>
          </div> */}
          <li>
            Proses produksi akan berlangsung setelah proses pembayaran dp atau
            full payment dilakukan dan pengiriman logo / font yang digunakan
            beserta design. Untuk proses produksi 2 minggu / 14 hari kerja, Bisa
            lebih cepat atau bisa lebih lama tergantung kesulitan design,
            quantity, dan ketersediaan bahan. Customer bisa menanyakan status
            pemesanan kapan saja kepada kami.
          </li>
          <li>
            Setelah proses produksi selesai, customer melakukan pelunasan.
          </li>
          <li>
            Setelah barang diterima, pastikan design dan ukuran sudah benar,
            karena batas waktu pergantian design atau ukuran hanya 1 x 24 jam.
          </li>
        </ol>
      </>
    );
  };

  render() {
    const { active, title } = this.state;

    return (
      <>
        <Meta />
        <div className="pattern_bg">
          <div className="container container_page">
            {this.renderSidebar()}
            <div className="container_content">
              <Breadcrumbs>
                <BreadcrumbsItem name="Home" to="/" />
                <BreadcrumbsItem name="FAQ &amp; Cara Order" active />
              </Breadcrumbs>
              <h1 className="page_title">{title}</h1>
              <div className="content_container container_text">
                {active === "1" ? this.renderFaq() : this.renderCO()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Faq;
