import React from "react";

const Twtdark = props => (
  <svg viewBox="0 0 21 19" width="1em" height="1em" {...props}>
    <g fillRule="nonzero" fill="none">
      <path d="M-6-7h32v32H-6z" />
      <path
        d="M19.12 2.508l1.414.165-.636 1.275-.931 1.865a1.475 1.475 0 0 1-.197.397c-.04.063-.177.262-.183.272a2.096 2.096 0 0 0-.082.131v2.389c0 6.112-5.915 9.995-11.501 9.995-1.143 0-2.012-.054-3.006-.271-1.637-.357-2.855-1.067-3.412-2.308l-.574-1.277 1.395-.129c1.261-.116 2.355-.356 3.195-.678-2.306-.37-3.598-1.383-3.598-3.285v-1h1c.22 0 .417-.018.595-.05C.868 8.963 0 7.303 0 5.001v-.204c-.007-1.16.055-1.78.373-2.571.204-.508.498-.977.894-1.407L2.023 0l.733.838c2.418 2.764 4.808 4.44 7.248 4.643.01-2.55 1.938-4.48 4.5-4.48 1.195 0 2.279.454 3.13 1.272.467.096.962.174 1.486.235zm-2.211 1.654l-.307-.07-.212-.232c-.512-.561-1.154-.858-1.886-.858-1.463 0-2.5 1.042-2.5 2.5 0 .238-.006.387-.036.584-.118.785-.558 1.416-1.464 1.416-2.998 0-5.703-1.607-8.34-4.35-.136.414-.168.838-.164 1.635l.001.215c0 2.048.83 3.168 2.794 3.792l.698.222v.731c0 .887-.84 1.722-2.07 2.096.563.428 1.716.659 3.58.659h1v1c0 1.432-1.645 2.554-4.132 3.14.81.248 1.89.355 3.133.355 4.614 0 9.5-3.208 9.5-7.995v-2.5c0-.294.086-.55.227-.814.055-.104.116-.203.192-.316l.108-.16.08-.158.384-.77a16.89 16.89 0 0 1-.586-.122z"
        fill="#171133"
      />
    </g>
  </svg>
);

export default Twtdark;
