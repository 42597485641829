import React from "react";

const Phone = props => (
  <svg viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <g fillRule="nonzero" fill="none">
      <path d="M-1-1h25v25H-1z" />
      <path
        d="M11 3.125a7.877 7.877 0 0 0-7.795 6.75H4c1.726 0 3.125 1.4 3.125 3.125v2a3.125 3.125 0 0 1-6.25 0v-4C.875 5.408 5.408.875 11 .875S21.125 5.408 21.125 11v4a3.125 3.125 0 0 1-6.25 0v-2c0-1.726 1.4-3.125 3.125-3.125h.795A7.877 7.877 0 0 0 11 3.125zm-7.875 9V15a.875.875 0 1 0 1.75 0v-2A.875.875 0 0 0 4 12.125h-.875zm14.875 0a.875.875 0 0 0-.875.875v2a.875.875 0 1 0 1.75 0v-2.875H18z"
        fill="#CDCDD2"
      />
      <path
        d="M18.875 13a1.125 1.125 0 0 1 2.25 0v4c0 2.548-1.051 4.125-3.125 4.125h-5a1.125 1.125 0 0 1 0-2.25h5c.593 0 .875-.423.875-1.875v-4z"
        fill="#CDCDD2"
      />
    </g>
  </svg>
);

export default Phone;
