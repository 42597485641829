import React from "react";

const Map = props => (
  <svg viewBox="0 0 16 18" width="1em" height="1em" {...props}>
    <g fill="#8376C0" fillRule="nonzero">
      <path d="M1.875 14.786l3.792-1.264 4.632 1.544 3.826-1.594V3.214l-3.792 1.264-4.666-1.556-3.792 1.264v10.6zm3.792.58L.125 17.215V2.924l5.542-1.846 4.666 1.555L15.875.786v13.853l-5.508 2.295-4.7-1.567z" />
      <path d="M9.458 3.556a.875.875 0 1 1 1.75 0V16a.875.875 0 1 1-1.75 0V3.556zM4.792 2a.875.875 0 1 1 1.75 0v12.444a.875.875 0 1 1-1.75 0V2z" />
    </g>
  </svg>
);

export default Map;
