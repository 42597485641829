import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ children, title }) => (
  <Helmet>
    <link
      rel="apple-touch-icon"
      sizes="512x512"
      href="/assets/images/favicon/apple-icon-512x512.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="/assets/images/favicon/favicon-192x192.png"
    />
    <link
      rel="mask-icon"
      href="/assets/images/favicon/apple-touch-icon.png"
      color="#FFF"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/assets/images/favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/assets/images/favicon/favicon-16x16.png"
    />
    <link rel="manifest" href="/assets/images/favicon/site.webmanifest" />
    {children}
  </Helmet>
);

export default Meta;
