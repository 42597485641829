import React from "react";
import { withFormik } from "formik";

import Meta from "../components/Meta/Meta";
import {
  Breadcrumbs,
  BreadcrumbsItem
} from "../components/Breadcrumbs/Breadcrumbs";
import Input from "../components/Form/Input/Input";
import Select from "../components/Form/Input/Select";
import Button from "../components/Button/Button";

import form from "../components/Form/Form.module.scss";

import { checkMyBrowser, scrollToMyRef } from "../helpers/util";

const subject = [
  {
    id: "1",
    label: "Orders"
  },
  {
    id: "2",
    label: "General Questions"
  }
];

const defaultSubject = {
  key: "",
  value: "Subjek"
};

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.successRef = React.createRef();
  }

  handleScroll = () => {
    const { status } = this.props;
    const browser = checkMyBrowser();
    scrollToMyRef(browser, this.myRef);

    return (
      <div className={form.form_error} ref={this.myRef}>
        {status}
      </div>
    );
  };

  handleSelect = e => {
    const { setFieldValue } = this.props;
    const targetId = e.target.id;
    const targetValue = e.target.value;

    setFieldValue(targetId, targetValue);
  };

  handleState = (errors, touched) => {
    if (errors && touched) return "error";
    if (!errors && touched) return "correct";
    return "";
  };

  renderText = () => (
    <>
      <p>
        Untuk semua pertanyaan terkait produk Serenite dapat diakses melalui
        kolom FAQ. Apabila jawaban yang dicari tidak ditemukan, silahkan hubungi
        kami via e-mail dan akan direspon sesegera mungkin.
      </p>
      <p>
        Jam kerja Serenite dari hari Senin sampai Jumat jam 08.30 - 17.30 (GMT
        0700) dan mohon maaf atas keterlambatan respon di luar jam kerja.
      </p>
      <p>
        Apabila ingin menghubungi kami, silahkan mengisi formulir pendek di
        bawah ini.
      </p>
    </>
  );

  renderForm = () => {
    const {
      values,
      errors,
      status,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    } = this.props;

    return (
      <div className={form.form_wrapper}>
        <form onSubmit={handleSubmit}>
          {status && this.handleScroll()}
          <div className={form.section_wrapper}>
            <div className={`${form[`input_label-block`]}`}>Subjek</div>
            <div className={form.input_block}>
              <Select
                id="user_subject"
                data={subject}
                defaultOption={defaultSubject}
                asKey="label"
                asValue="label"
                value={values.user_subject}
                onChange={e => this.handleSelect(e)}
                onBlur={handleBlur}
                message={errors.user_subject}
                state={this.handleState(
                  errors.user_subject,
                  touched.user_subject
                )}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={`${form[`input_label-block`]}`}>Nama Lengkap</div>
            <div className={form.input_block}>
              <Input
                type="text"
                id="user_name"
                value={values.user_name}
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_name}
                state={this.handleState(errors.user_name, touched.user_name)}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={`${form[`input_label-block`]}`}>Alamat Email</div>
            <div className={form.input_block}>
              <Input
                type="email"
                id="user_email"
                value={values.user_email}
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_email}
                state={this.handleState(errors.user_email, touched.user_email)}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={`${form[`input_label-block`]}`}>
              Nomor Handphone
            </div>
            <div className={form.input_block}>
              <Input
                type="email"
                id="user_phone"
                value={values.user_phone}
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_phone}
                state={this.handleState(errors.user_phone, touched.user_phone)}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div
              className={`${form[`input_label-block`]} ${
                form[`input_label-top`]
              }`}
            >
              Pesan
            </div>
            <div className={form.input_block}>
              <Input
                type="textarea"
                id="user_message"
                value={values.user_message}
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_message}
                state={this.handleState(
                  errors.user_message,
                  touched.user_message
                )}
              />
            </div>
          </div>
          <div className={`${form.section_wrapper} ${form.button_wrapper}`}>
            <div className={form.button_block}>
              <Button type="submit" disabled={isSubmitting}>
                KIRIM
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  renderSuccess = () => {
    const browser = checkMyBrowser();
    scrollToMyRef(browser, this.myRef);

    return (
      <div className={form.form_success} ref={this.successRef}>
        <div className={form[`form_success-icon`]}>&#10004;</div>
        Terima kasih telah menghubungi kami.
        <br />
        Kami akan segera menghubungi Anda kembali untuk menjawab pertanyaan
        Anda.
      </div>
    );
  };

  render() {
    const { values } = this.props;

    return (
      <>
        <Meta />
        <div className="pattern_bg">
          <div className="container-m">
            <Breadcrumbs>
              <BreadcrumbsItem name="Home" to="/" />
              <BreadcrumbsItem name="Kontak" active />
            </Breadcrumbs>
            <h1 className="page_title">KONTAK</h1>
            <div className="content_container container_text">
              {this.renderText()}
              {values.success ? this.renderSuccess() : this.renderForm()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const ContactValidate = withFormik({
  mapPropsToValues: () => ({
    user_subject: "",
    user_name: "",
    user_email: "",
    user_phone: "",
    user_message: ""
  }),

  validate: values => {
    const errors = {};

    if (!values.user_subject) {
      errors.user_subject =
        "Silakan pilih salah satu subjek yang Anda inginkan";
    }

    if (!values.user_name) {
      errors.user_name = "Nama Anda harus diisi";
    }

    if (!values.user_email && !values.user_phone) {
      errors.user_email = "Silakan isi e-mail atau nomor handphone Anda";
      errors.user_phone = "Silakan isi e-mail atau nomor handphone Anda";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.user_email) &&
      !values.user_phone
    ) {
      errors.user_email = "Format e-mail salah";
    } else if (
      !/^(\()?(\+628|628|08|\d)(\d{2,3})?\)?[ .-]?\d{2,4}[ .-]?\d{2,4}[ .-]?\d{2,4}$/i.test(
        values.user_phone
      ) &&
      !values.user_email
    ) {
      errors.user_phone = "Format nomor handphone Anda salah";
    }

    if (!values.user_message) {
      errors.user_message = "Pesan harus diisi";
    }

    return errors;
  },

  handleSubmit: async (
    values,
    { setSubmitting, setStatus, setValues, resetForm }
  ) => {
    fetch(process.env.REACT_APP_MAIL_URL + "/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: JSON.stringify({
        subject: values.user_subject,
        fullname: values.user_name,
        email: values.user_email,
        phone: values.user_phone,
        message: values.user_message
      })
    })
      .then(response => {
        setValues({ ...values, success: true });
        setSubmitting(false);
        setTimeout(() => {
          setValues({ ...values, success: false });
          resetForm();
        }, 3000);
      })
      .catch(error => {
        setStatus("Terjadi kesalahan. Silakan coba beberapa saat lagi.");
        setSubmitting(false);
      });
  },

  displayName: "ContactForm"
})(Contact);

export default ContactValidate;
