import React from "react";

import Icon from "../../Icon/Icon";

import style from "./Input.module.scss";
import form from "../Form.module.scss";

const labelStyle = {
  display: "block",
  width: "100%",
  height: "50px"
};

const InputFile = props => {
  const { id, name, value, filename, accept, onChange } = props;

  return (
    <div className={form[`inputfile-outer`]}>
      <label htmlFor={id} style={labelStyle}>
        <input
          type="file"
          id={id}
          name={name}
          className={`${style.input} ${style.inputfile}`}
          value={value}
          accept={accept}
          onChange={onChange}
          {...props}
        />
        <span className={style.inputfile_label}>
          {filename.name ? (
            <>
              <Icon type="uploaded" className={style.inputfile_icon} />
              {filename.name}
            </>
          ) : (
            <>
              <Icon type="upload" className={style.inputfile_icon} />
              Unggah Gambar
            </>
          )}
        </span>
      </label>
    </div>
  );
};

export default InputFile;
