import React from "react";

import style from "./Button.module.scss";

const getClass = background => {
  const list = [`${style.button}`];
  list[list.length] = background ? `${style[`button--${background}`]}` : null;

  return list.join(" ");
};

const Button = ({
  type,
  disabled,
  background,
  children,
  onClick,
  ...props
}) => (
  <button
    type={type}
    className={getClass(background)}
    disabled={disabled}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export default Button;
