import React from "react";

const Mclose = props => (
  <svg viewBox="0 0 12 12" width="1em" height="1em" {...props}>
    <path
      d="M1.757 11.657a1 1 0 0 1-1.414-1.414l9.9-9.9a1 1 0 0 1 1.414 1.414l-9.9 9.9zm9.9-1.414a1 1 0 1 1-1.414 1.414l-9.9-9.9A1 1 0 0 1 1.757.343l9.9 9.9z"
      fill="#433AB1"
      fillRule="nonzero"
    />
  </svg>
);

export default Mclose;
