import React from "react";
import { withFormik } from "formik";

import Input from "../Form/Input/Input";
import InputFile from "../Form/Input/InputFile";
import Select from "../Form/Input/Select";
import Button from "../Button/Button";

import style from "./Forms.module.scss";
import input from "../Form/Input/Input.module.scss";
import form from "../Form/Form.module.scss";

import { checkMyBrowser, scrollToMyRef } from "../../helpers/util";

import { getCategory } from "../../api";
import worktime from "../../api/JSON/worktime.json";

const defaultTime = {
  id: 0,
  value: "Pilih waktu pengerjaan"
};

const defaultProduct = {
  id: 0,
  value: "Pilih jenis produk"
};

class OrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: []
    };
  }

  componentDidMount = async () => {
    const category = await getCategory();

    this.setState({
      category: category.data
    });
  };

  handleState = (errors, touched) => {
    if (errors && touched) return "error";
    if (!errors && touched) return "correct";
    return "";
  };

  handleSelect = e => {
    const { setFieldValue } = this.props;
    const targetId = e.target.id;
    const targetValue = e.target.value;

    setFieldValue(targetId, targetValue);
  };

  handleFile = e => {
    const { values, setValues } = this.props;
    const targetId = e.target.id;
    const targetValue = e.currentTarget.files[0];

    setValues({ ...values, [targetId]: targetValue });
  };

  handleScroll = () => {
    const { status } = this.props;
    const browser = checkMyBrowser();
    scrollToMyRef(browser, this.myRef);

    return (
      <div className={form.form_error} ref={this.myRef}>
        {status}
      </div>
    );
  };

  renderForm = () => {
    const {
      status,
      values,
      errors,
      touched,
      handleSubmit,
      handleChange,
      handleBlur,
      isSubmitting
    } = this.props;

    const { category } = this.state;
    const time = worktime.data;

    return (
      <div className={form.form_wrapper}>
        <form onSubmit={handleSubmit}>
          {status && this.handleScroll()}
          <div className={form.section_wrapper}>
            <div className={form.input_label}>Nama</div>
            <div className={form.input}>
              <Input
                type="text"
                id="user_name"
                value={values.user_name}
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_name}
                state={this.handleState(errors.user_name, touched.user_name)}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={form.input_label}>Email</div>
            <div className={form.input}>
              <Input
                type="email"
                id="user_email"
                value={values.user_email}
                placeholder="mail@example.com"
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_email}
                state={this.handleState(errors.user_email, touched.user_email)}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={form.input_label}>Nomor Telepon / Seluler</div>
            <div className={form.input}>
              <Input
                type="text"
                id="user_phone"
                value={values.user_phone}
                placeholder="081345678xxx"
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_phone}
                state={this.handleState(errors.user_phone, touched.user_phone)}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={form.input_label}>
              Nama Perusahaan{" "}
              <span
                className={`${form.input_label} ${form[`input_label-thin`]}`}
              >
                (Opsional)
              </span>
            </div>
            <div className={form.input}>
              <Input
                type="text"
                id="user_company"
                value={values.user_company}
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_company}
                state={this.handleState(
                  errors.user_company,
                  touched.user_company
                )}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={form.input_label}>Jenis Produk</div>
            <div className={form.input}>
              <Select
                id="user_product"
                data={category}
                defaultOption={defaultProduct}
                asKey="category"
                asValue="category"
                value={values.user_product}
                onChange={e => this.handleSelect(e)}
                onBlur={handleBlur}
                message={errors.user_product}
                state={this.handleState(
                  errors.user_product,
                  touched.user_product
                )}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={form.input_label}>Jumlah Order (Qty)</div>
            <div className={form.input}>
              <Input
                type="number"
                id="user_qty"
                value={values.user_qty}
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_qty}
                state={this.handleState(errors.user_qty, touched.user_qty)}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={form.input_label}>Deskripsi</div>
            <div className={form.input}>
              <Input
                type="textarea"
                id="user_message"
                value={values.user_message}
                onChange={handleChange}
                onBlur={handleBlur}
                message={errors.user_message}
                state={this.handleState(
                  errors.user_message,
                  touched.user_message
                )}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={form.input_label}>Waktu Pengerjaan</div>
            <div className={form.input}>
              <Select
                id="user_time"
                data={time}
                defaultOption={defaultTime}
                asKey="value"
                asValue="value"
                value={values.user_time}
                onChange={e => this.handleSelect(e)}
                onBlur={handleBlur}
                message={errors.user_time}
                state={this.handleState(errors.user_time, touched.user_time)}
              />
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={form.input_label}>
              Mohon lampirkan konsep desain atau referensi
            </div>
            <div className={`${form.input} ${form.input_file}`}>
              <InputFile
                id="user_img"
                filename={values.user_img}
                accept=".png, .jpg, .jpeg"
                onChange={e => this.handleFile(e)}
                onBlur={handleBlur}
              />
              {values.user_img ? (
                <InputFile
                  id="user_img2"
                  filename={values.user_img2}
                  accept=".png, .jpg, .jpeg"
                  onChange={e => this.handleFile(e)}
                  onBlur={handleBlur}
                />
              ) : null}
              {values.user_img2 ? (
                <InputFile
                  id="user_img3"
                  filename={values.user_img3}
                  accept=".png, .jpg, .jpeg"
                  onChange={e => this.handleFile(e)}
                  onBlur={handleBlur}
                />
              ) : null}
            </div>
            {errors.user_img && touched.user_img ? (
              <div className={input.input_message}>{errors.user_img}</div>
            ) : null}
            <div className={input.inputfile_type}>
              Type File: JPG, JPEG, PNG
            </div>
          </div>
          <div className={form.section_wrapper}>
            <div className={form.button_full}>
              <Button type="submit" disabled={isSubmitting}>
                KIRIM
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  renderSuccess = () => {
    const browser = checkMyBrowser();
    scrollToMyRef(browser, this.myRef);

    return (
      <div className={form.form_success} ref={this.successRef}>
        <div className={form[`form_success-icon`]}>&#10004;</div>
        Terima kasih telah membeli produk kami.
        <br />
        Kami akan segera menghubungi Anda kembali untuk membahas pesanan Anda
        lebih lanjut
      </div>
    );
  };

  render() {
    const { values } = this.props;

    return (
      <div className={style[`form--order`]}>
        <div className={style[`formo_wrapper-img`]}>
          <img
            src="/assets/images/banner_mockup.png"
            alt="order-form"
            className={style.formo_img}
          />
        </div>
        <div className={style.formo_wrapper}>
          <div className={style.formo_title}>BUAT CUSTOM DESIGN</div>
          <div className={style.formo_desc}>
            Mohon lengkapi data dibawah ini untuk selanjutnya kami kirimkan
            detail penawaran ke alamat e-mail Anda
          </div>
          {values.success ? this.renderSuccess() : this.renderForm()}
        </div>
      </div>
    );
  }
}

const OrderValidate = withFormik({
  mapPropsToValues: () => ({
    user_name: "",
    user_email: "",
    user_phone: "",
    user_company: "",
    user_product: "",
    user_qty: "",
    user_desc: "",
    user_time: "",
    user_img: "",
    user_img2: "",
    user_img3: ""
  }),

  validate: values => {
    const errors = {};

    if (!values.user_name) {
      errors.user_name = "Nama Anda harus diisi";
    }

    if (!values.user_email && !values.user_phone) {
      errors.user_email = "Silakan isi e-mail atau nomor handphone Anda";
      errors.user_phone = "Silakan isi e-mail atau nomor handphone Anda";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.user_email) &&
      !values.user_phone
    ) {
      errors.user_email = "Format e-mail salah";
    } else if (
      !/^(\()?(\+628|628|08|\d)(\d{2,3})?\)?[ .-]?\d{2,4}[ .-]?\d{2,4}[ .-]?\d{2,4}$/i.test(
        values.user_phone
      ) &&
      !values.user_email
    ) {
      errors.user_phone = "Format nomor handphone Anda salah";
    }

    if (!values.user_product) {
      errors.user_product =
        "Silakan pilih salah satu produk yang Anda inginkan";
    }

    if (!values.user_qty) {
      errors.user_qty = "Silakan isi jumlah produk yang Anda inginkan";
    }

    if (!values.user_time) {
      errors.user_time =
        "Silakan pilih estimasi waktu pengerjaan yang Anda inginkan";
    }

    if (!values.user_img) {
      errors.user_img =
        "Silakan lampirkan konsep desain atau referensi untuk produk yang Anda inginkan";
    } else if (
      values.user_img.size > 1000000 ||
      values.user_img2 > 1000000 ||
      values.user_img3 > 1000000
    ) {
      errors.user_img = "Ukuran maximal file 1MB";
    }

    return errors;
  },

  handleSubmit: async (
    values,
    { setSubmitting, setStatus, setValues, resetForm }
  ) => {
    const formData = new FormData();

    formData.append("name", values.user_name);
    formData.append("email", values.user_email);
    formData.append("phone", values.user_phone);
    formData.append("company", values.user_company);
    formData.append("product_type", values.user_product);
    formData.append("qty", values.user_qty);
    formData.append("description", values.user_desc);
    formData.append("processing_time", values.user_time);
    formData.append("files_upload_1", values.user_img);
    formData.append("files_upload_2", values.user_img2);
    formData.append("files_upload_3", values.user_img3);

    fetch(process.env.REACT_APP_MAIL_URL + "/api/request_order", {
      method: "POST",
      body: formData
    })
      .then(response => {
        setValues({ ...values, success: true });
        setSubmitting(false);
        setTimeout(() => {
          setValues({
            ...values,
            success: false
          });
          resetForm();
        }, 3000);
      })
      .catch(err => {
        setStatus("Terjadi kesalahan. Silakan coba beberapa saat lagi.");
        setSubmitting(false);
      });
  },

  displayName: "OrderForm"
})(OrderForm);

export default OrderValidate;
