import React from "react";
import { Link } from "react-router-dom";

import Optselect from "../Form/Input/Optselect";
import Select from "../Form/Input/Select";

import style from "./Sidebar.module.scss";
import button from "../Button/Button.module.scss";

const getClass = customClass => {
  const list = [`${style.sidebar_wrapper}`];
  list[list.length] = customClass;

  return list.join(" ");
};

const getDefaultClass = active => {
  const list = [`${button.link}`];
  list[list.length] = active ? `${style[`sidebar_item-active`]}` : null;

  return list.join(" ");
};

const sidebarTitle = title => (
  <h3 className={`${style.sidebar_title} page_title--m`}>{title}</h3>
);

const SidebarItem = ({ name, active, to, onClick }) => (
  <li className={style.sidebar_item}>
    <Link to={to} className={getDefaultClass(active)} onClick={onClick}>
      <span>{name}</span>
    </Link>
  </li>
);

const Sidebar = ({ title, customClass, children }) => (
  <div className={getClass(customClass)}>
    {title ? sidebarTitle(title) : null}
    <ul className={style.sidebar_menu}>{children}</ul>
  </div>
);

const SidebarMobile = ({
  title,
  customClass,
  data,
  asKey,
  asValue,
  value,
  onChange
}) => (
  <div className={getClass(customClass)}>
    {title ? sidebarTitle(title) : null}
    <Select
      id="sidebar-menu"
      data={data}
      asKey={asKey}
      asValue={asValue}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SidebarProductMobile = ({
  title,
  customClass,
  data,
  asKey,
  asValue,
  value,
  onChange
}) => (
  <div className={getClass(customClass)}>
    {title ? sidebarTitle(title) : null}
    <Optselect
      id="sidebar-menu"
      data={data}
      asKey={asKey}
      asValue={asValue}
      value={value}
      onChange={onChange}
    />
  </div>
);

// const SidebarProductMobile = menu => {
//   return (
//     <div>
//       <div className={style.product_active}>
//         Kaos
//         <Icon type="down" size="m" className={style.select_arrow} />
//       </div>
//       <div className={style.product_menu}>
//         <ul className={style.product_category}>
//           {menu.menu.map(_el => {
//             return (
//               <li
//                 key={_el.category_id}
//                 className={style[`product_category-list`]}
//               >
//                 {_el.category}
//                 <ul className={style.product_subcategory}>
//                   {_el.subcategory.map(_ol => {
//                     return (
//                       <li
//                         key={_ol.subcategory_id}
//                         className={style[`product_subcategory-list`]}
//                       >
//                         <Link
//                           to={`/products/${_el.category_id}/${
//                             _ol.subcategory_id
//                           }`}
//                           className={style.subcategory_link}
//                         >
//                           {_ol.name}
//                         </Link>
//                       </li>
//                     );
//                   })}
//                 </ul>
//               </li>
//             );
//           })}
//         </ul>
//       </div>
//     </div>
//   );
// };

export { Sidebar, SidebarItem, SidebarMobile, SidebarProductMobile };
