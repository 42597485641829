import React from "react";
import { Link } from "react-router-dom";

import style from "./ProductCard.module.scss";

const getClass = className => {
  const list = [`${style.product_wrapper}`];
  list[list.length] = className;

  return list.join(" ");
};

const getDefaultClass = oos => {
  const list = [`${style[`product_wrapper--img`]}`];
  list[list.length] = oos ? `${style.product_oos}` : null;

  return list.join(" ");
};

const renderStockLabel = oos => {
  if (oos) return <div className={style[`product_oos--label`]}>STOK HABIS</div>;
};

const ProductCard = ({
  to,
  productImage,
  productName,
  productDesc,
  oos,
  className,
  props
}) => (
  <div className={getClass(className)}>
    <div className={getDefaultClass(oos)}>
      <Link to={to}>
        <img
          src={productImage}
          alt={productName}
          className={style.product_img}
        />
        {renderStockLabel(oos)}
      </Link>
    </div>
    <div className={style.product_info}>
      <div className={style.product_name}>{productName}</div>
      <div className={style.product_desc}>{productDesc}</div>
    </div>
  </div>
);

export default ProductCard;
