import React from "react";

import Icon from "../Icon/Icon";

import style from "./Accordion.module.scss";

const getDefaultClass = className => {
  const list = [`${style[`accordion_wrapper--title`]}`];
  list[list.length] = className;

  return list.join(" ");
};

const getClass = contentClass => {
  const list = [`${style.accordion_content}`];
  list[list.length] = contentClass;

  return list.join(" ");
};

const AccordionSection = ({
  show,
  title,
  className,
  contentClass,
  children,
  onClick
}) => (
  <div className={show}>
    <button
      type="button"
      className={getDefaultClass(className)}
      onClick={onClick}
    >
      <div className={style.accordion_title}>{title}</div>
      <div className={style.accordion_icon}>
        <Icon type="iplus" size="s" />
      </div>
    </button>
    <div className={getClass(contentClass)}>{children}</div>
  </div>
);

export default AccordionSection;
