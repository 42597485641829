import React from "react";
import { Link } from "react-router-dom";

import style from "./Breadcrumbs.module.scss";

const getClass = customClass => {
  const list = [`${style.breadcrumbs_wrapper}`];
  list[list.length] = customClass;

  return list.join(" ");
};

const getDefaultClass = active => {
  const list = [`${style.breadcrumbs_link}`];
  list[list.length] = active ? `${style[`breadcrumbs_link-active`]}` : null;

  return list.join(" ");
};

const BreadcrumbsItem = ({ name, active, to }) => (
  <div className={style.breadcrumbs}>
    <Link to={active ? "/" : to} className={getDefaultClass(active)}>
      {name}
    </Link>
  </div>
);

const Breadcrumbs = ({ customClass, children }) => {
  return <div className={getClass(customClass)}>{children}</div>;
};

export { Breadcrumbs, BreadcrumbsItem };
