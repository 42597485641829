export function keyDownListener(evt, code, func) {
  evt = evt || window.event;
  if (evt.keyCode === code) {
    func();
  }
}

export function removeElement(el) {
  el.parentNode.removeChild(el);
  return false;
}

export const checkMyBrowser = () => {
  const ua = window.navigator.userAgent;
  const trident = ua.indexOf("Trident/");
  let browser = "";

  if (trident > 0) {
    browser = "ie";
  } else if (ua.indexOf("firefox") > 0) {
    browser = "firefox";
  } else if (ua.indexOf("SAMSUNG") > 0) {
    browser = "samsung";
  } else if (ua.indexOf("Android") > 0) {
    browser = "android";
  } else {
    browser = "other";
  }

  return browser;
};

export const scrollToMyRef = (browser, myRef) => {
  if (browser === "ie" || browser === "samsung" || browser === "android") {
    window.scrollTo(0, myRef);
  } else {
    window.scrollTo({
      top: myRef,
      behavior: "smooth"
    });
  }
};

export const checkScreenSize = () => {
  const width = window.innerWidth;

  if (width <= 575) {
    return true;
  }
  return false;
};
